// src/components/graphicsSVG/graphicsSVG.js

import React from "react"
import PropTypes from "prop-types"
/*** awards ***/
import DesignPriceSwitzerland from "../../../assets/logos/design-price-switzerland-white.svg"
import Europrix from "../../../assets/logos/europrix-white.svg"
import RedDot from "../../../assets/logos/red-dot-white.svg"
/**+ clients ***/
// import GizLogo from "../../../assets/logos/giz.svg"
import GizLogo from "../../../assets/logos/giz-white.svg"
import Cornelsen from "../../../assets/logos/cornelsen-gray.svg"
// import OrangeLogo from "../../../assets/logos/orange.svg"
import OrangeLogo from "../../../assets/logos/orange-white.svg"
// import AuswaertigesAmtLogo from "../../../assets/logos/auswaertiges-amt.svg"
import AuswaertigesAmtLogo from "../../../assets/logos/auswaertiges-amt-gray.svg"
// import BertelsmannStiftungLogo from "../../../assets/logos/bertelsmann-stiftung.svg"
import BertelsmannStiftungLogo from "../../../assets/logos/bertelsmann-stiftung-gray.svg"
// import EthLogo from "../../../assets/logos/eth.svg"
import Eth from "../../../assets/logos/eth-white.svg"
import Golem from "../../../assets/logos/golem-gray.svg"
import ZhdkLogo from "../../../assets/logos/zhdk-gray.svg"
import ZzfLogo from "../../../assets/logos/zzf-gray.svg"
import BaunetzLogo from "../../../assets/logos/baunetz-gray.svg"
import MaterialArchiv from "../../../assets/logos/material-archiv-gray.svg"
import HausDerFarbe from "../../../assets/logos/haus-der-farbe-gray.svg"
import UX from "../../../assets/services/user-experience-design.svg"
import UXMD from "../../../assets/services/user-experience-design-md.svg"
import UXstrategy from "../../../assets/services/ux-product-strategy.svg"
import UXstrategyMD from "../../../assets/services/ux-product-strategy-md.svg"
import Production from "../../../assets/services/production.svg"
import ProductionMD from "../../../assets/services/production-md.svg"


import { failIfNotExist } from "../../utils/helper"


// Dynamic Components - here icons from react-icons
// @see https://stackoverflow.com/questions/29875869/react-jsx-dynamic-component-name
const graphicComponents = {
  design_price_switzerland_gray: DesignPriceSwitzerland,
  europrix_gray: Europrix,
  red_dot_gray: RedDot,
  giz_gray: GizLogo,
  cornelsen_gray: Cornelsen,
  eth_gray: Eth,
  bertelsmann_stiftung_gray: BertelsmannStiftungLogo,
  haus_der_farbe_gray: HausDerFarbe,
  auswaertiges_amt_gray: AuswaertigesAmtLogo,
  golem_gray: Golem,

  orange_gray: OrangeLogo,
  zhdk_gray: ZhdkLogo,
  zzf_gray: ZzfLogo,
  baunetz_gray: BaunetzLogo,
  material_archiv_gray: MaterialArchiv,

  ux: UX,
  ux_md: UXMD,
  uxStrategy: UXstrategy,
  uxStrategy_md: UXstrategyMD,
  production: Production,
  production_md: ProductionMD,
}

 const GraphicsSVG = ({
  id,
  width = '100%',
  height = '100%',
  title= ''
}) => {
  const Svg = graphicComponents[id]

  failIfNotExist(
    Svg,
    `in graphicsSVG: Did not find an apropriate SVG-Component ${id}`
  )

  return <Svg width={width} height={height} title={title} />
}

GraphicsSVG.propTypes = {
  id: PropTypes.string.isRequired,
}

export default GraphicsSVG