// src/components/contact/contact.js

import React from "react"
import { ReactIcon } from "../icons"
import data from "../../data/yaml/repeating_sections.yaml"
import { BrightSection } from "../sections"
import { LinkButtonPrimary } from "../buttons"

import { CALENDER_CHECK, LINKEDIN_ICON } from "../icons"
import {HeadlineH4} from "../headline"
import { FullRow, IndentedRow } from "../rows"

const Contact = ({headline}) => {
  const { textHtml2 } = data.contact
  const linkedinContextValue = {
    color: "#FF645D",
    size: "30px",
    style: { verticalAlign: "middle" },
  }

  const urlService = "#"
  const labelService = "Vereinbaren Sie ein Termin"
  return (
    <BrightSection className="pb-24">
      <FullRow>
        <HeadlineH4
          // overwrite headline take it from contact data 
          headline={headline || data.contact.headline}
          subheadline={data.contact.subheadline}
        />
      </FullRow>

      <IndentedRow>
        <p className="pb-4" dangerouslySetInnerHTML={{ __html: textHtml2 }} />
        <a
          href="https://www.linkedin.com/in/pepe-jürgens-121b42125/"
          className=" text-primary-500 hover:underline"
        >
          <div className="flex items-center pb-6">
            <span className="pr-2">
              {
                <ReactIcon
                  id={LINKEDIN_ICON}
                  contextValue={linkedinContextValue}
                />
              }
            </span>
            <span>LinkedIn</span>
          </div>
        </a>
        {/* <LinkButtonPrimary
          url={urlService}
          label={labelService}
          useAsLabelProp="labelLong"
          iconId={CALENDER_CHECK}
        /> */}
      </IndentedRow>
    </BrightSection>
  )
}

export default Contact
