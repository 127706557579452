import { useMediaQuery } from "react-responsive"

export const XLquery = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 1280 })
  return isDesktop ? children : null
}
export const LGquery = ({ children }) => {
   const isDesktop = useMediaQuery({ minWidth: 1024, maxWidth: 1279 })
  return isDesktop ? children : null
}
export const MDquery = ({ children }) => {
   const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1023 })
  return isTablet ? children : null
}
export const SMquery = ({ children }) => {
   const isMobile = useMediaQuery({ minWidth: 640, maxWidth: 767 })
  return isMobile ? children : null
}
export const DefaultQuery = ({ children }) => {
   const isNotMobile = useMediaQuery({ maxWidth: 639 })
  return isNotMobile ? children : null
}

// from 0 until 767 (smartphone sm)
export const UntilSmartphoneWidthQuery = ({ children }) => {
  const isNotMobile = useMediaQuery({ maxWidth: 767 })
  return isNotMobile ? children : null
}

// from 0 until 1023 (tablet md)
export const UntilTabletWidthQuery = ({ children }) => {
  const isNotMobile = useMediaQuery({ maxWidth: 1023 })
  return isNotMobile ? children : null
}
// from 768 until end (desktop md)
export const FromTabletWidthQuery = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 })
  return isNotMobile ? children : null
}

// from 1024 until end (desktop lg)
export const FromDesktopWidthQuery = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 1024 })
  return isNotMobile ? children : null
}